import React, { useState } from 'react';
import './App.css';
import { jwtDecode } from 'jwt-decode';


const mockWidth = '500px';


export const popUp = (isUploading, imgSource, title, text, uploadFinish, buttonFunction) => {
    if (isUploading) {
        return (
            <div className="popup-overlay">
                <div className="popup-content"> 
                    <img className= "popUpImgDiv" src={imgSource} alt={title} />
                    <h1>{title}</h1> 
                    <p>{text}</p> 
                </div>
            </div>
        );
    } if (uploadFinish){
          return(
            <div className="popup-overlay">
                <div className="popup-content"> 
                    <img className= "popUpImgDiv" src={imgSource} alt={title} />
                    <h1>{title}</h1> 
                    <p>{text}</p> 
                    <button className ="primaryButton" onClick = {buttonFunction} > close </button>
                </div>
            </div>)
  }


    return null; // Return null if not uploading
};

export const getUsername = () => {
  const token = localStorage.getItem('token'); // Get token from localStorage
  if (token) {
    try {
      const decodedToken = jwtDecode(token); // Decode the JWT token
      return decodedToken.username; // Return the username from the decoded token
    } catch (error) {
      console.error('Error decoding JWT token:', error); // Log the decoding error
      return 'guest';
    }
  }
  if (!token){
    console.log('no token');
  }

  return null; // Return null if no token is found
};

export const getProfileImg = () => {
  const storedUsername = getUsername().toLowerCase();
  const profileImgPath = `./profile/${storedUsername}.jpg`;

  return profileImgPath

};

export const App = () => {
  const [style, setStyle] = useState({
    width: '200px',
    position: 'absolute',
    left: '50%', // Set the left to 50% for centering by default
    transform: 'translateY(-50%)', // Center horizontally
  });

  // Function to align the image to the left
  const posLeft = () => {

    setStyle({
      position: 'absolute',
      left: '0', // Align to the left
      transform: 'none', // Reset any transformation
    });
  };

  // Function to center the image
  const posCenter = () => {
    setStyle({
      position: 'absolute',
      left: '50%', // Center the image horizontally
      transform: 'translateY(-100%)', // Translate to adjust for the image width
    });
  };

  // Function to align the image to the right
  const posRight = () => {
    setStyle({
      position: 'absolute',
      right: '0', // Align to the right
      left: 'auto', // Reset left
      transform: 'none', // Reset any transformation
    });
  };

  // Sample uploaded image URL and dimensions
  const uploadedImage = "path_to_your_uploaded_image"; // replace with actual image source
  const dimensions = { width: 500, height: 500 }; // example dimensions

  return (
    <div>
      <button onClick={posLeft}>Move Left</button>
      <button onClick={posCenter}>Center</button>
      <button onClick={posRight}>Move Right</button>
      <ShirtComponent uploadedImage={uploadedImage} style={style} dimensions={dimensions} />
    </div>
  );
};

export const ShirtComponent = ({ uploadedImage, style, dimensions, shirtColour, isFront }) => {
  return (
    <div style={{ position: 'relative', width: mockWidth, height: 'auto', margin: '0 auto' }}>
      {/* T-shirt base image */}
      <img
        src={shirtColour}
        alt="T-shirt"
        style={{
          width: '100%',
          position: 'relative',
        }}
      />

      {/* Container for uploaded design */}
      <div
        style={{
          position: 'absolute',
          top: `${isFront ? 49 : 44}%`, // Dynamically set top position based on the front or back side
          left: '49%',
          width: '59%', // Based on T-shirt design proportions (3508px for DPI)
          height: '84%', // Dynamic height for uploaded design (4961px for DPI)
          transform: 'translate(-50%, -50%) scale(0.65)', // Center and scale the design
        }}
      >
        {/* User's uploaded image */}
        {uploadedImage && (
          <img
            src={uploadedImage}
            alt="Design preview"
            style={{
              width: `${((1 / 3508) * dimensions.width) * 100}%`, // Calculate width based on image dimensions and scale
              opacity: 0.95,
              ...style, // Apply dynamic style here (left, right, center positioning)
            }}
          />
        )}
      </div>
    </div>
  );
};
