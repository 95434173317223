import { useNavigate } from 'react-router-dom';

export const useNavFunctions = () => {


  const navigate = useNavigate();

  const navUpload = () => {
    navigate('/upload');
  };

  const navEarnings = () => {
    navigate('/past-earnings');
  };

  const navTnC = () => {
    navigate('/tnc');
  };

const logout = () => {
  // Show confirmation dialog
  const isConfirmed = window.confirm("Are you sure you want to log out?");

  // If user confirms, proceed with logout
  if (isConfirmed) {
    localStorage.removeItem('token');
    localStorage.clear();
    alert("Logged out");
    navigate('/');
  }
};

  return {
    navUpload,
    navEarnings,
    navTnC,
    logout
  };

};

