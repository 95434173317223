import React from 'react';

const DocumentViewer = ({ file }) => {
  return (
    <iframe
      src={file}
      width="100%"
      height="600px"
      title="Document Viewer"
    >
      This browser does not support PDFs. Please download the PDF to view it: <a href={file}>Download Document</a>
    </iframe>
  );
};

export default DocumentViewer;
