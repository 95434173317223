// App.js or wherever you manage routes
import React, { useState } from 'react';
import { HashRouter as Router, Route, Routes } from 'react-router-dom'; // Use HashRouter instead of BrowserRouter
import Login from './login.js';
import Upload from './Upload.js';
import Edit from './Edit.js';
import PastEarnings from './PastEarnings.js';
import TnC from './TnC.js';

function App() {
  const [username, setUsername] = useState('');

  const handleLogin = (user) => {
    setUsername(user); // Store the logged-in username
  };

  return (
    <Router>
      <Routes>
        <Route path="/" element={<Login onLogin={handleLogin} />} />
        <Route path="/upload" element={<Upload username={username} />} /> {/* Pass username as prop */}
        <Route path="/designs" element={<Edit username={username} />} /> {/* Pass username as prop */}
        <Route path="/past-earnings" element={<PastEarnings username={username} />} /> {/* Pass username as prop */}
        <Route path="/tnc" element={<TnC username={username} />} /> {/* Pass username as prop */}
      </Routes>
    </Router>
  );
}

export default App;
