import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useNavFunctions } from './navBar.js';
import { getProfileImg, getUsername } from './components.js';

function Edit() {

    const { navUpload, navEarnings, navTnC,logout } = useNavFunctions();
    const storedUsername = getUsername();
    const [tableData, setTableData] = useState([]);
    const [collectionLink, setCollectionLink] = useState('#'); // State for link

    const apiBaseUrl = process.env.NODE_ENV === 'development'
        ? 'http://localhost:5000/get-sheet-data'
        : 'https://bonappetees-qns9.onrender.com/get-sheet-data';

    const apiBaseURL_updateSheet = process.env.NODE_ENV === 'development'
        ? 'http://localhost:5000/api/update-sheet'
        : 'https://bonappetees-qns9.onrender.com/api/update-sheet';

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(apiBaseUrl, { params: { username: storedUsername } });
                setTableData(response.data);

                // Set the collection link dynamically based on storedUsername
                if (storedUsername) {
                    setCollectionLink(`https://bonappetees.shop/${storedUsername}`);
                }

            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        if (storedUsername) {
            fetchData();
        }
    }, [storedUsername, apiBaseUrl]);

    const handleDelete = async (rowIndex) => {
        const row = tableData[rowIndex];

        const deleteRequestDate = new Date().toLocaleString('en-SG', {
            timeZone: 'Asia/Singapore',
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric',
        });

        try {
            const response = await fetch(apiBaseURL_updateSheet, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    designID: row[0],
                    uploadDate: deleteRequestDate,
                    username: row[2],
                    designTitle: row[3],
                    shirtColours: row[4],
                    frontFile: row[5],
                    frontDesignPos: row[6],
                    backFile: row[7],
                    action: 'Deleted'
                }),
            });

            if (response.ok) {
                alert('Successfully deleted!');
                window.location.reload();
                // Optionally, you can remove the deleted row from tableData or refetch data
                setTableData((prevData) => prevData.filter((_, i) => i !== rowIndex));
            } else {
                alert('Error requesting delete');
            }
        } catch (error) {
            console.error('Error during delete request:', error);
        }
    };

return (
    <div className = 'mainDiv'>

    <div className = 'leftNav'> {/* Start Left Column Section */ }
        <div className = 'navBarRows'> {/* Start LeftBar */ }


          <div>
          <img
            src={getProfileImg()}
            alt="BonAppeTees"
            className = 'profileImg'
          />  

          </div>
          Hello

          <div style ={{marginBottom: '24px', fontSize: '24px'}}> 
          {storedUsername} !
          </div> 
          <button className = "navBarButtons" style ={{borderRadius: '8px', color: '#4ca9eb', background: 'white'}} onClick={logout} > Upload Design </button>
          <button className = "navBarButtons" onClick={navEarnings} > Earnings </button>
          <button className = "navBarButtons" onClick={navTnC} > Guide</button>
          <button className = "navBarButtons" onClick={logout} > Logout </button>

        </div>  {/* End LeftBar */ }
    </div> {/* End Left Column Section */ }
        <div className = "rightMain"> {/* Start Right Column Section */}

            <div> {/* Start NavBar */}
            <button className="breadButtons" onClick={navUpload} > Upload </button>
            <button className="breadButtons" style={{borderBottom: '1px solid black', fontWeight:'bold'}}> Edit Uploaded </button>

            </div> {/* End NavBar */}

                <h1>Uploaded Designs</h1>
                <p> Designs you've uploaded that're not on the shop yet.</p>
        <div style ={{width: '100%'}}>
                {tableData.length > 0 ? (
                    <table className = "tableDesign">
                        <thead>
                            <tr >
                                <th className = "tableColumn"> Design Name</th> {/* Index 3 */}
                                <th className = "tableColumn"> Shirt Colours </th>
                                <th className = "tableColumn" style ={{border: '0px solid '}}>Upload Date (SGT)</th> {/* Index 1 */}
                                <th ></th> {/* Action Button */}
                            </tr>
                        </thead>
                        <tbody>
                            {tableData.map((row, rowIndex) => (
                                <tr key={rowIndex}>
                                    <td className ="tableColContent">{row[3]}</td> {/* Upload Date */}
                                    <td className ="tableColContent"> {row[4]}</td>
                                    <td className ="tableColContent">{row[1]} </td> {/* Upload Date*/}
                                    <td className ="deleteCol">
                                        <button className="deleteButton" onClick={() => handleDelete(rowIndex)}>Delete</button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                ) : (
                    <p>{storedUsername ? 'No designs uploaded' : 'Please log in to see your data'}</p>
                )}
            </div>
            <div>
                <p>
                    <a href={collectionLink} target="_blank" rel="noopener noreferrer" >View Shop Collection</a>
                </p>
            </div>
        </div>

    </div> /* End Right Column Section */
    );
}

export default Edit;