import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useNavFunctions } from './navBar.js';
import { getUsername, getProfileImg } from './components.js';  

function PastEarnings() {

  const { navUpload, navEarnings, navTnC,logout} = useNavFunctions();

  const storedUsername = getUsername();
  const [tableData, setTableData] = useState([]);
  const [collectionLink, setCollectionLink] = useState('#'); // State for link
  const [itemData , setItemData] = useState([]);
  const [bankData ,setBankData] = useState([]);
  const [promoData, setPromoCode] = useState([]);


  const apiBaseUrl = process.env.NODE_ENV === 'development'
    ? 'http://localhost:5000/get-earnings-data'
    : 'https://bonappetees-qns9.onrender.com/get-earnings-data';


  const getItemData = process.env.NODE_ENV === 'development'
    ? 'http://localhost:5000/get-itemBreakdown'
    : 'https://bonappetees-qns9.onrender.com/get-itemBreakdown';

  const getBankData = process.env.NODE_ENV === 'development'
    ? 'http://localhost:5000/get-bankDetails'
    : 'https://bonappetees-qns9.onrender.com/get-bankDetails';

  const getPromoCode= process.env.NODE_ENV === 'development'
    ? 'http://localhost:5000/get-PromoCodes'
    : 'https://bonappetees-qns9.onrender.com/get-PromoCodes';





 useEffect(() => {
  const fetchData = async () => {
    try {
      
      const response = await axios.get(apiBaseUrl, { params: { username: storedUsername } });
      setTableData(response.data);

      const responseItem = await axios.get(getItemData, { params: { username: storedUsername } });
      setItemData(responseItem.data);

      const responseBank = await axios.get(getBankData, { params: { username: storedUsername } });
      setBankData(responseBank.data);

      const responsePromo = await axios.get(getPromoCode);
      setPromoCode(responsePromo.data);

      if (storedUsername) {
        setCollectionLink('https://forms.gle/N341WHcE6wrfM7nV6');
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  if (storedUsername) {
    fetchData();
  }
}, [storedUsername, apiBaseUrl]);


  return (
    <div className='mainDiv'>
      <div className='leftNav'> {/* Start Left Column Section */}
        <div className='navBarRows'> {/* Start LeftBar */}
          <div>
            <img
              src={getProfileImg()}
              alt="BonAppeTees"
              className='profileImg'
            />
          </div>
          Hello

          <div style={{ marginBottom: '24px', fontSize: '24px' }}>
            {storedUsername} !
          </div>
          <button className="navBarButtons" onClick={navUpload} > Upload Design </button>
          <button className="navBarButtons"  style={{ borderRadius: '8px', color: '#4ca9eb', background: 'white' }} onClick={navEarnings} >Earnings </button>
          <button className="navBarButtons" onClick={navTnC} > Guide </button>
          <button className="navBarButtons" onClick={logout} > Logout </button>
        </div> {/* End LeftBar */}
      </div> {/* End Left Column Section */}

      <div className="rightMain"> {/* Start Right Column Section */}

        <h1>Monthly Earnings</h1>
        <p> Payout every third Friday of the month (for transactions in previous month) </p>
        <div style={{ width: '100%' }}>
          {tableData.length > 0 ? (
            <table className="tableDesign">
              <thead>
                <tr>
                  <th className="tableColumn">Month</th> {/* Index 1 */}
                  <th className="tableColumn"> Earnings (SGD) </th>
                  <th className="tableColumn"> Status </th>
                  <th></th> {/* Action Button */}
                </tr>
              </thead>
              <tbody>
                {tableData.map((row, rowIndex) => (
                  <tr key={rowIndex}>
                    <td className="tableColContent">{row[1]}</td> {/* Design Name */}
                    <td className="tableColContent">${row[2]}</td> {/* Design */}
                    <td className="tableColContent">{row[3]}</td> {/* Design */}
   
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <p>{storedUsername ? 'No designs uploaded' : 'Please log in to see your data'}</p>
          )}
        </div>

        

      <div className ="sectionDivider"></div>

        <div>
            <h1> Items Breakdown</h1>
        <p> Breakdown of total cumulative transactions (as of latest payout month) </p>

  <div style={{ width: '100%' }}>
          {itemData.length > 0 ? (
            <table className="tableDesign">
              <thead>
                <tr>
                  <th className="tableColumn">Design Name</th> {/* Index 1 */}
                  <th className="tableColumn">Qty Sold </th>
                  <th className="tableColumn" style= {{width: '250px'}}> Earnings (SGD) </th>
                  <th></th> {/* Action Button */}
                </tr>
              </thead>
              <tbody>
                {itemData.map((row, rowIndex) => (
                  <tr key={rowIndex}>
                    <td className="tableColContent">{row[1]}</td> {/* Design Name */}
                    <td className="tableColContent">{row[2]}</td> {/* Design */}
                    <td className="tableColContent">${row[3]}</td> {/* Design */}
   
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <p>{storedUsername ? 'No Items Sold Yet' : 'Please log in to see your data'}</p>
          )}
        </div>
         </div>

      <div className ="sectionDivider"></div>

 <div> 
    <h1> Artist Bulk Promo</h1>
    <p> For bulk order purchase (this does not impact your payout amount) </p>
  <div style={{ width: '100%' }}>
          {promoData.length > 0 ? (
            <table className="tableDesign">
              <thead>
                <tr>
                  <th className="tableColumn">Promo Code</th> {/* Index 1 */}
                  <th className="tableColumn">% Off</th>
                  <th className="tableColumn"> Min Qty </th>
                  <th></th> {/* Action Button */}
                </tr>
              </thead>
              <tbody>
                {promoData.map((row, rowIndex) => (
                  <tr key={rowIndex}>
                    <td className="tableColContent">{row[0]}</td> {/* Design Name */}
                    <td className="tableColContent">{row[1]}</td> {/* Design */}
                    <td className="tableColContent">{row[2]}</td> {/* Design */}
   
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <p>{storedUsername ? 'Loading..' : 'Please log in to see your data'}</p>
          )}
        </div>
</div>


      <div className ="sectionDivider"></div>

        <div>
            <h1> Bank Details</h1>
          

  <div style={{ width: '100%', marginBottom:'16px'}}>
          {bankData.length > 0 ? (
            <table className="tableDesign">
              <thead>
                <tr>
                  <th className="tableColumn">Full Name</th> {/* Index 1 */}
                  <th className="tableColumn">Bank </th>
                  <th className="tableColumn" style= {{width: '500px'}}> Account No. </th>
                  <th></th> {/* Action Button */}
                </tr>
              </thead>
              <tbody>
                {bankData.map((row, rowIndex) => (
                  <tr key={rowIndex}>
                    <td className="tableColContent">{row[1]}</td> {/* Design Name */}
                    <td className="tableColContent">{row[2]}</td> {/* Design */}
                    <td className="tableColContent">{row[3]}</td> {/* Design */}
   
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <p>{storedUsername ? 'Loading...' : 'Please log in to see your data'}</p>
          )}
        </div>
          
         </div>

        </div>
      </div>
  );
}

export default PastEarnings;
