import './App.css';
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {popUp, getUsername} from './components.js';


function Login({ onLogin }) {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false); // State to manage password visibility
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [popUpImg, setPopUpImg] = useState("./states/pending.png")
  const [popUpTitle, setTitle] = useState("Logging In...")
  const [popUpBody, setText] = useState("Sit tight!")
  const [error, setError] = useState(false)

  const refreshPage = async () => {
      window.location.reload(); 
  };

  const apiBaseUrl = process.env.NODE_ENV === 'development'
  ? 'http://localhost:5000/login'  // Local development URL
  : 'https://bonappetees-qns9.onrender.com/login';  // Production URL


  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      navigate('/upload'); // Redirect to /upload if the user is already logged in
    }
  }, [navigate]);
  const handleSubmit = async (e) => {
    e.preventDefault();

        setLoading(true);


    try {


      const response = await fetch(apiBaseUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ username, password }),
      });
    


      if (response.ok) {
        const data = await response.json();
        localStorage.setItem('token', data.token); // Store JWT in localStorage

        onLogin(username); // Use the username from the response

          navigate('/upload'); // Navigate to /upload

      } else {
        setError(true)
        setPopUpImg("./states/fail.png")
        setTitle("Error..")
        setText("Apologies ")
      }
    } catch (error) {
      console.error('Login error:', error);
      alert('An error occurred during login');
    } finally{ 
        setLoading(false);
    }


  };

  return (

    <div className="loginDiv"> 
          {popUp(loading, popUpImg, popUpTitle, popUpBody, error, refreshPage)} {/* Render the popup here */}


      <div className ="loginSection"> 
          <img
            src='/BonAppeTees_LogoBlack.png' 
            alt="BonAppeTees"
            width={100}
          />
      <h1>BonAppeTees Artist Login</h1>

      <div className = "loginInput"> 
      <form onSubmit={handleSubmit}>
        <input
          className = "inputField_login"
          type="text"
          placeholder="Username"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          required
        />

        <input
          className= "inputField_login"
          type={showPassword ? 'text' : 'password'} // Toggle between 'text' and 'password'
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
        />
          <label style={{ display: 'block', textAlign: 'left' }}>
          <input
            type="checkbox"
            checked={showPassword}
            onChange={() => setShowPassword(!showPassword)} // Toggle showPassword state
          />
          Show Password
        </label>
        <button className = "primaryButton" type="submit">Login</button>
      </form>

      </div> 
    </div>

      <p style={{color: 'grey'}}> Have issues logging in? Email hello@bonappetees.shop</p>

  </div> 
  );
}

export default Login;
