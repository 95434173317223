import './App.css';
import React, { useState, useRef, useEffect } from 'react';
import { ShirtComponent , popUp, getUsername, getProfileImg} from './components.js';
import { useNavigate } from 'react-router-dom';
import { useNavFunctions} from './navBar.js'




function Upload( {username} ) {


 const { navUpload, navEarnings, navTnC,logout } = useNavFunctions();
 const navigate = useNavigate();
const storedUsername = getUsername();


   const apiBaseURL_updateSheet = process.env.NODE_ENV === 'development'
       ? 'http://localhost:5000/api/update-sheet'  // Local development URL
       : 'https://bonappetees-qns9.onrender.com/api/update-sheet';  // Production URL

      //useEffect(() => {


        //Redirect if username is empty
        //if (!storedUsername) {
          //navigate('/'); // Redirect to home or login page if username is empty
          //alert('Please enter your username and password');
        //}
      //}, [storedUsername, navigate]);


 const [frontFile, setFrontFile] = useState(null);
 const [backFile, setBackFile] = useState(null);
 const [dimensions, setDimensions] = useState({ front: { width: 0, height: 0 }, back: { width: 0, height: 0 } });
 const [frontStyle, setFrontStyle] = useState({ position: 'absolute', left: '50%', transform: 'translateX(-50%)' });
 const [backStyle, setBackStyle] = useState({ position: 'absolute', left: '50%', transform: 'translateX(-50%)' });
 const [shirtColour, setShirtColour] = useState("/whiteTeeFront.png");
 const [backShirtColour, setBackShirtColour] = useState("/whiteTeeBack.png");
 const [isUploading, setIsUploading] = useState(false);
 const [frontDesignPos, setPos] = useState('center');
 const [uploadDate, setUploadDate] = useState(null); //set Current date
 const [designTitle, setDesignTitle] = useState(''); // Initialize state to manage input value
  const roundNumber = (number) => {
   const roundedNumber = Math.round(number*100)/100
   return roundedNumber
 }


const[popUpImg, setPopUpImg] = useState("/states/pending.png")
const[popUpTitle, setPopUpTitle] = useState("Uploading")
const[popUpBody, setPopUpBody] = useState("Transferring pixels...")

const [uploadFinish, setUpLoadFinish] = useState(false);

const refreshPage = async () => {
    window.location.reload(); 
};

//Change Shirt Colour Buttons


const [previousColour, setPreviousColour] = useState(''); // Track previous color




const updateTee = (colour) => {
 setPreviousColour(shirtColour.includes('TeeFront') ? shirtColour.split('TeeFront')[0] : shirtColour.split('TeeBack')[0]);
 setShirtColour(`${colour}TeeFront.png`);
 setBackShirtColour(`${colour}TeeBack.png`);
};

 const [isClicked, setIsClicked] = useState({
   white: true,
   black: false 
});
//change colour - v1


const [selectedColours, setSelectedColours] = useState(['white']);
 const updateColours = (colour) => {
   // If the clicked colour is not in the array, add it
   if (!selectedColours.includes(colour)) {
     setSelectedColours((prevColours) => {
       const updatedColours = [...prevColours, colour]; // Add the new colour
       return updatedColours; // Return the updated colours
     });
   } else {
     // If the clicked colour is already in the array, remove it
     setSelectedColours((prevColours) => {
       const updatedColours = prevColours.filter(item => item !== colour); // Remove the clicked colour
      
       // Automatically select the opposite colour if the array becomes empty
       if (updatedColours.length === 0) {
         const oppositeColour = colour === 'white' ? 'black' : 'white';
         return [oppositeColour]; // Select the opposite colour
       }


       return updatedColours; // Return the updated colours
     });
   }
 };


 const onClickFunctions = (colour) => {
   setIsClicked((prevState) => {
     // Toggle the clicked state for the current colour
     const newState = {
       ...prevState,
       [colour]: !prevState[colour],
     };


     // If the clicked colour is being toggled off, automatically toggle the other colour on
     if (!newState[colour]) {
       const oppositeColour = colour === 'white' ? 'black' : 'white';
       newState[oppositeColour] = true; // Set the opposite colour button to clicked
     }


     return newState; // Return the updated state
   });


   // Update the selected colours array
   updateColours(colour);
 };

const designTitleChange = (e) => {
 setDesignTitle(e.target.value); // Access the target value correctly
};


 const canvasRef = useRef(null);


 const [leftButtonClicked, setLeft] = useState(false);
 const [centerButtonClicked, setCenter] = useState(true);
 const [rightButtonClicked, setRight] = useState(false);




 // Update position functions
 const posLeftFront = () => {
   setFrontStyle({ position: 'absolute', left: '0' });
   setPos('left'); // Change frontDesignPos
   setLeft(true);
   setCenter(false);
   setRight(false);


 };
  const posCenterFront = () => {
   setFrontStyle({ position: 'absolute', left: '50%', transform: 'translateX(-50%)' });
   setPos('center'); // Change frontDesignPos
   setLeft(false);
   setCenter(true);
   setRight(false);
 };


 const posRightFront = () => {
   setFrontStyle({ position: 'absolute', right: '0' });
   setPos('right'); // Change frontDesignPos
   setLeft(false);
   setCenter(false);
   setRight(true);
 };


 // Move this out of the useEffect to avoid triggering a re-render
 const combineImages = async (isFront = true) => {
   const activeCanvas = canvasRef.current;
   const ctx = activeCanvas.getContext('2d');
   const shirtImage = new Image();
   const designImage = new Image();


   return new Promise((resolve, reject) => {
     ctx.clearRect(0, 0, activeCanvas.width, activeCanvas.height);
     shirtImage.src = isFront ? shirtColour : backShirtColour; // set shirtImagesourc


     shirtImage.onload = () => {
       activeCanvas.width = shirtImage.width;
       activeCanvas.height = shirtImage.height;
       ctx.drawImage(shirtImage, 0, 0, shirtImage.width, shirtImage.height);
      
     if (isFront ? frontFile : backFile) {
         designImage.src = URL.createObjectURL(isFront ? frontFile: backFile);


         designImage.onload = () => {
           const frontWidth = ((1 / 3508) * designImage.width * (0.59 * shirtImage.width)) * 0.65;
           const frontHeight = (frontWidth / designImage.width) * designImage.height;


           let calculatedXPos;


           if (frontDesignPos === 'center') {
             calculatedXPos = (activeCanvas.width - frontWidth) / 2;
           } else if (frontDesignPos === 'left') {
             calculatedXPos = ((activeCanvas.width - frontWidth) / 2) + (79.44 - (frontWidth/2) ); //previously 384, 79.44
           } else if (frontDesignPos === 'right') {
             calculatedXPos = ((activeCanvas.width - frontWidth) / 2) - (84.24- (frontWidth/2));
           }


           ctx.drawImage(designImage, calculatedXPos, isFront ? 252: 210 , frontWidth, frontHeight); //252 for front, 210 for back. for 1200px x 1200px size,
           const combinedImage = activeCanvas.toDataURL('image/png');
           resolve(combinedImage);
         };
       } else {
         resolve(null);
       }
     };


     shirtImage.onerror = (error) => {
       reject(error);
     };
     designImage.onerror = (error) => {
       reject(error);
     };
   });
 };
 useEffect(() => {
   const fetchImages = async () => {
     if (frontFile) {
       await combineImages();
     }
   };
   fetchImages();
 }, [frontFile, dimensions, frontDesignPos]); // Add dependencies as needed


// Function to create a folder in Google Drive
const createFolderInDrive = async (folderName) => {
   const response = await fetch('https://bonappetees-qns9.onrender.com/create-folder', {
       method: 'POST',
       headers: {
           'Content-Type': 'application/json',
       },
       body: JSON.stringify({ folderName }), // Send the folder name
   });


   if (!response.ok) {
       const errorText = await response.text();
       throw new Error('Folder creation failed: ' + errorText);
   }


   const folderData = await response.json();


   return folderData.id; // Return the folder ID
};


// Function to upload a file to Google Drive
const uploadFileToDrive = async (file, folderID) => {
   const form = new FormData();
   form.append('file', file);
   form.append('folderID', folderID); // Pass the folder ID


   try { //http://localhost:5000/   |  https://bonappetees-qns9.onrender.com/upload
       const response = await fetch('https://bonappetees-qns9.onrender.com/upload', {
           method: 'POST',
           body: form,
       });


       if (!response.ok) {
           const errorText = await response.text();
           throw new Error('Upload failed: ' + errorText);
       }
       return await response.json();
   } catch (error) {
       throw new Error('Upload failed: ' + error.message);
   }
};

//loading 


// Main function to handle the upload process
const handleUpload = async () => {


   if (!frontFile && !backFile) {
       alert('Please upload front or back design.');
       return;
   }


  const randomID = storedUsername + Math.random().toString(36)

  const frontDimension = `${roundNumber(dimensions.front.width * (29.7 / 3508))} x ${roundNumber(dimensions.front.height * (29.7 / 3508))}`;
  const backDimension = `${roundNumber(dimensions.back.width * (29.7 / 3508))} x ${roundNumber(dimensions.back.height * (29.7 / 3508))}`;



   const currentDate = new Date().toLocaleString('en-SG', {
       timeZone: 'Asia/Singapore',
       year: 'numeric',
       month: 'long',
       day: 'numeric',
       hour: 'numeric',
       minute: 'numeric',
       second: 'numeric',
   });


   setUploadDate(currentDate);
   setIsUploading(true);

   try {
      
       // Create a new folder in Google Drive
      const folderName = `${storedUsername}-${designTitle}`
      const folderId = await createFolderInDrive(folderName); // Create folder and get ID


       // Upload front file
       if (frontFile) {
           const renamedFrontFile = new File([frontFile], `${designTitle}-front.png`, { type: frontFile.type });
           const frontUploadSuccess = await uploadFileToDrive(renamedFrontFile, folderId); // Pass folderId

           // Combine images logic
           if (frontUploadSuccess) {
               const combinedImageFront = await combineImages(true);
               if (combinedImageFront) {
                   const frontBlob = await (await fetch(combinedImageFront)).blob();
                   const combinedFrontBlob = new File([frontBlob], `${designTitle}-front-tee.png`, { type: 'image/png' });
                   await uploadFileToDrive(combinedFrontBlob, folderId); // Pass folderId

               }
           }
       }
       // Upload back file
       if (backFile) {
           const renamedBackFile = new File([backFile], `${designTitle}-back.png`, { type: backFile.type });
           const backUploadSuccess = await uploadFileToDrive(renamedBackFile, folderId); // Pass folderId


           // Combine images logic
           if (backUploadSuccess) {
               const combinedImageBack = await combineImages(false);
               if (combinedImageBack) {
                   const backBlob = await (await fetch(combinedImageBack)).blob();
                   const combinedBackBlob = new File([backBlob], `${designTitle}-back-tee.png`, { type: 'image/png' });
                   await uploadFileToDrive(combinedBackBlob, folderId); // Pass folderId


               }
           }
       }


       // Send data to Google Sheets
       const response = await fetch(apiBaseURL_updateSheet, {
           method: 'POST',
           headers: {
               'Content-Type': 'application/json',
           },
           body: JSON.stringify({
               designID: randomID,
               uploadDate: currentDate,
               username: storedUsername,
               designTitle: designTitle,
               shirtColours: selectedColours.join(', '),
               frontFile: frontFile ? 'true' : 'false',
               frontDesignPos: frontDesignPos,
               backFile: backFile ? 'true' : 'false',
               action: 'Uploaded',
               frontDimension: frontDimension, 
              backDimension: backDimension
           }),
       });


       if (response.ok) {
           setUpLoadFinish(true);
           setPopUpImg("./states/success.png");
           setPopUpTitle("Upload Success!")
           setPopUpBody("Your designs will be on the shop in 3 working days") 
       } else {
          alert('An error occurred during upload. Please try again.')
           //window.location.reload(); 

       }
       // Reset state
       resetCanvas();
       setFrontFile(null);
       setBackFile(null);
   } catch (error) {
       alert('An error occurred during upload: ' + (error.message || JSON.stringify(error)));
   } finally {
       setIsUploading(false);
   }
};



 const frontImage = (e) => {
   const selectedFile = e.target.files[0];
   if (selectedFile) {
     setFrontFile(selectedFile);
     const img = new Image();
     img.src = URL.createObjectURL(selectedFile);
     img.onload = () => {
       setDimensions((prev) => ({ ...prev, front: { width: img.width, height: img.height } }));
     };
   }
 };


 const backImage = (e) => {
   const selectedFile = e.target.files[0];
   if (selectedFile) {
     setBackFile(selectedFile);
     const img = new Image();
     img.src = URL.createObjectURL(selectedFile);
     img.onload = () => {
       setDimensions((prev) => ({ ...prev, back: { width: img.width, height: img.height } }));
     };
   }
 };


 const resetCanvas = () => {
   const activeCanvas = canvasRef.current;
   const ctx = activeCanvas.getContext('2d');
   ctx.clearRect(0, 0, activeCanvas.width, activeCanvas.height);
   activeCanvas.width = 5000;
   activeCanvas.height = 5000;
 };




 const editDesigns = async (e) => {
     navigate('/designs'); // Navigate to /upload


 };


return (
  <div className = 'mainDiv'> {/* Start Main Div */}
          {popUp(isUploading, popUpImg, popUpTitle, popUpBody, uploadFinish, refreshPage)} {/* Render the popup here */}



   <div className = 'leftNav'> {/* Start Left Column Section */ }
       <div className = 'navBarRows'> {/* Start LeftBar */ }
         <div>
         <img
           src= {getProfileImg()}
           alt="BonAppeTees"
           className = 'profileImg'
         /> 


         </div>
         Hello


         <div style ={{marginBottom: '24px', fontSize: '24px'}}>
         {storedUsername} !
         </div>
         <button className = "navBarButtons" style ={{borderRadius: '8px', color: '#4ca9eb', background: 'white'}} onClick = {navUpload}> Upload Design </button>
         <button className = "navBarButtons" onClick={navEarnings} >Earnings </button>
         <button className = "navBarButtons" onClick={navTnC} > Guide </button>
         <button className = "navBarButtons" onClick={logout} > Logout </button>


       </div>  {/* End LeftBar */ }
   </div> {/* End Left Column Section */ }


   <div className = "rightMain"> {/* Start Right Column Section */}


       <div> {/* Start NavBar */}
         <button className="breadButtons" style={{borderBottom: '1px solid black', fontWeight:'bold'}} > Upload </button>
         <button className="breadButtons" onClick = {editDesigns}> Edit Uploaded </button>


       </div> {/* End NavBar */}


       <div> {/* Start Upload Title */}
         <h1>Upload Your Design </h1>
         <p> You can upload up to A3 size for Front and Back. Files should be 300 DPI, in .png format</p>


       </div> {/* End Upload Title */}


       <div className ='top-container'> {/* Start Design Input Div */ }
           <div className = 'top-subContainer'> {/*  Start Input Design Name */}
             <p className = 'fieldTitleText'> Design Name: {designTitle} </p>


             <label  htmlFor="inputField"></label>
                 <input style = {{width: '90%'}}
                   className ="inputField"
                   placeholder="Enter your design's name"
                   type="text"
                   id="inputField"
                   value={designTitle}
                   onChange={(e) => designTitleChange(e)} // Pass the event explicitly
               />
           </div> {/* End Input Design Name */ }


           <div className = 'top-subContainer'> {/* Start Choose Colours Section */ }
             <p className = 'fieldTitleText'>Selected Shirt Colour(s): {selectedColours.join(', ') || ''}</p>

             <div className = 'buttonRows'> {/* Start Choose Colour Buttons */}
                 <button
                   className={`buttonPos ${isClicked.white ? 'alignBClicked' : ''}`}
                   onClick = {() => {onClickFunctions('white'); updateTee('white')}}
                   onMouseEnter = { () => updateTee('white')}
                 > white </button>

                 <button
                   className={`buttonPos ${isClicked.black ? 'alignBClicked' : ''}`}
                   onClick = {() => {onClickFunctions('black'); updateTee('black')  }}
                   onMouseEnter = { () => updateTee('black')}
                   > black </button>

               </div> {/* End Choose Colour Buttons */}


           </div> {/* End Choose Colours Section */ }


       </div>  {/* End Design Input Div */ }


         <div className = "subDiv"> {/* Start Tee Upload And Button */}
           <div className="grid-container"> {/* Start Upload Front and Back Div */}


             <div className="divBorder" style={{ borderRight: '0.5px solid #c9c9c9' }}> {/* Start Front Tee Div */}
               <h3> Front Tee </h3>
               <input className = "designUpload" type="file" accept="image/*" onChange={frontImage} />




               <ShirtComponent
                 uploadedImage={frontFile && URL.createObjectURL(frontFile)}
                 shirtColour={shirtColour}
                 dimensions={dimensions.front}
                 style={frontStyle}
                 isFront = {true}
               />


             <div className = "positionSection">  {/* Start Design Position Section */}


               <div>
                 <p>Design Size: {roundNumber(dimensions.front.width* (29.7/3508))} x {roundNumber(dimensions.front.height*(29.7/3508))} cm</p>
               </div>

               <div className ="buttonsContainer"> {/* Start Design Position Section */}
                 <button className= {`buttonPos ${leftButtonClicked ? 'alignBClicked': ''}`}  onClick={posLeftFront}>Left</button>
                 <button className= {`buttonPos ${centerButtonClicked ? 'alignBClicked': ''}`} onClick={posCenterFront}>Center</button>
                 <button className= {`buttonPos ${rightButtonClicked ? 'alignBClicked': ''}`} onClick={posRightFront}>Right</button>
               </div> {/* End Design Position Section */}
             </div>{/* End Design Position Section */}
             </div> {/* End Front Tee Div */}


             <div className="divBorder"> {/* Start Back Tee Div */}
               <h3> Back Tee </h3>
               <input  type="file" accept="image/*" onChange={backImage} class="designUpload" />
               <ShirtComponent
                 uploadedImage={backFile && URL.createObjectURL(backFile)}
                 shirtColour={backShirtColour}
                 dimensions={dimensions.back}
                 style={backStyle}
                 isFront = {false}
               />


               <div className = "positionSection">
                 <p>Design Size: {roundNumber(dimensions.back.width* (29.7/3508))} x {roundNumber(dimensions.back.height* (29.7/3508))} cm</p>
                </div>
             </div> {/* End Back Tee Div */}


           </div> {/* End Front and Back Div */}




         <div className = "uploadButSection">  {/* Start Button Section */}
         <div className = "uploadButtonDiv" > {/* Start Button Upload Section */}


           <button
             className="primaryButton"
             onClick={handleUpload}
             disabled={isUploading}
           >
             {isUploading ? 'Uploading...' : 'Confirm and Upload'}
           </button>

           <p> Once submitted, design will be in the shop in 3 working days.
           <n>If you have any issues, you may whatsapp me (Alvin) at +65 8753 3173 or email hello@bonappetees.shop</n></p>
           <canvas id="canvas" ref={canvasRef} style={{ display: 'none' }} />


         </div> {/* End Button Upload Section */}


       </div>{/* End Button Section */}
         </div> { /* End Tee Upload And Button */ }


   </div>  {/* End Right Column Section */}
</div> /* End Main Div */


);
}
export default Upload;

